<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>
        <el-button type="danger" size="small" :disabled="!btn.delete" @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getStatsList()"> 查询</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="refresh()"> 刷新</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'stat_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getStatsList"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { get, getNoCatch, post, postNoCatch } from '@api/request';
import { statAPI } from '@api/modules/stat';
import { stffAPI } from '@/api/modules/staff';
import { custAPI } from '@/api/modules/cust';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tableProperties } from '@/views/FinanceManagement/StatManage/stat';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'StatList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      dateRange: [],
      tableProperties: tableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      stffUserList: [],
      custOptionList: []
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/stat_edit' || from.path === '/stat_add') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getStatsList();
      this.getStffUser();
      this.initCust();
    },
    async getStatsList() {
      await (this.loadFlag = true);
      let searchForm = this.$refs.multiTable.searchForm;
      let [startTime, endTime] = this.dateRange || [];
      searchForm.startTime = startTime ? startTime / 1000 : null;
      searchForm.endTime = endTime ? endTime / 1000 : null;
      getNoCatch(statAPI.getStats, searchForm).then(({ data }) => {
        this.loading = false;
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 获取客户
    initCust() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custOptionList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(err => console.error(err));
    },
    // 获取人员信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(err => console.error(err));
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/stat_add?perm_id=${permId}`);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/stat_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.stat_id
          })
        )
      });
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //客户信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.stat_id);
          });
          postNoCatch(statAPI.deleteStatByIds, { stat_id_list: ids }).then(() => {
            this.$message.success('删除成功!');
            this.refresh();
          });
        })
        .catch(() => this.$message.info('取消删除!'));
    },
    refresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.getStatsList();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
