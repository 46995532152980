import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '结算单号', prop: 'stat_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '待审核状态',
    prop: 'stat_state',
    itemType: 'select',
    options: [
      { value: 0, label: '可审核' },
      { value: 1, label: '不可审核' }
    ],
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => (val === 0 ? '可审核' : '不可审核')
  },
  { label: '公司抬头', prop: 'cptt_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '结算金额', prop: 'stat_total', itemType: 'input', input: false, sortable: false, widthAuto: true },
  store.state.stff_name,
  {
    prop: 'create_time',
    label: '录入时间',
    sortable: false,
    itemType: 'date',
    labelWidth: '120px',
    formatter: val => getDateNoTime(val, true)
  },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    fixed: 'right',
    sortable: false,
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];
